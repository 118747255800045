import acceseditionetude from "@content/advanced/images/acces-edition-etude.png";
import modeeditionmodele from "@content/advanced/images/modeeditionmodele.png";
import creervariable from "@content/advanced/images/creervariable.png";
import typevariable from "@content/advanced/images/typevariable.png";
import exemplevariable from "@content/advanced/images/exemple-variable.png";
import paramsavances from "@content/advanced/images/variableadvancedsettings.png";
import alertes from "@content/advanced/images/alertes.png";
import editervariable from "@content/advanced/images/editvariable.png";

import { PageLayout, Section, SubSection, Note } from "@components";

import "@styles/Page.scss";

export default function FeatVariables() {
  return (
    <PageLayout title="Variables" roles={["admin"]}>
      <Section
        id="accessing-variable-editing"
        title="Accessing Variable Editing"
      >
        <p>
          Go to the <b>Design</b> section and then <b>Study Editing</b>.
        </p>
        <img
          src={acceseditionetude}
          alt="Study Editing Tab"
          className="centered"
        />
        <p>
          Switch the interface to <b>Template Editing</b> mode by clicking the
          toggle at the top right of your screen.
        </p>
        <img
          src={modeeditionmodele}
          alt="Switch to Template Editing Mode"
          className="centered"
        />
      </Section>
      <Section id="creating-a-variable" title="Creating a Variable">
        <p>
          There are two different ways to add variables in Dotter.science:
          <li>
            Go to the <b>Variables</b> section (1) and then click the <b>+</b>{" "}
            button (2)
          </li>
          <li>
            Hover over your section and click the <b>Add a New Variable</b>{" "}
            button (3) that appears.
          </li>
        </p>
        <img src={creervariable} alt="Create a Variable" className="centered" />
        <p>Choose the type of variable you wish to configure.</p>
        <img src={typevariable} alt="Types of Variable" className="centered" />
        <p>There are 8 possible types of variables.</p>
        <div className="responsive-table">
          <table>
            <tr>
              <td>Type of Variable</td>
              <td>Description</td>
            </tr>
            <tr>
              <td>Date or Time</td>
              <td>Allows you to record a date and/or a time.</td>
            </tr>
            <tr>
              <td>Measurement</td>
              <td>
                Allows you to record a number under a certain unit{" "}
                <em>(e.g., weight in kg)</em>, supports conversion
                <em>(e.g., kilograms to grams)</em>.
              </td>
            </tr>
            <tr>
              <td>Text Field</td>
              <td>Allows you to record a short text or a paragraph.</td>
            </tr>
            <tr>
              <td>Boolean</td>
              <td>
                Allows you to record a binary variable with a value of "
                <b>True</b>" or "<b>False</b>".
              </td>
            </tr>
            <tr>
              <td>Single Choice</td>
              <td>
                Displays a list of options from which the user can select only{" "}
                <b>one</b>.
              </td>
            </tr>
            <tr>
              <td>Multiple Choice</td>
              <td>
                Displays a list of options from which the user can select{" "}
                <b>multiple</b>.
              </td>
            </tr>
            <tr>
              <td>Calculation</td>
              <td>
                Uses existing variables in your study to calculate a new value{" "}
                <em>
                  (e.g., calculating BMI from a previously entered weight and
                  height)
                </em>
              </td>
            </tr>
            <tr>
              <td>Static Content</td>
              <td>
                Displays a block of text that cannot be modified by the user{" "}
                <em>(e.g., providing specific instructions for the form)</em>.
              </td>
            </tr>
          </table>
        </div>
        <p>A new window will then appear to configure the new variable.</p>
      </Section>
      <Section id="configuring-a-variable" title="Configuring a Variable">
        <SubSection title="General Parameters">
          <img
            src={exemplevariable}
            alt="General Parameters of a Variable"
            className="centered"
          />
          <p>
            The popup allows you to set the variable by filling in the following
            fields:
            <li>
              <b>Variable Name</b>: as it will appear in the database (simple
              name or shortcut)
            </li>
            <li>
              <b>Variable Label</b>: as it is visible on the platform (full
              name)
            </li>
            <li>
              <b>Notice</b>: help displayed on hover while filling out the form
            </li>
            <li>
              Value permissions for <b>Not Applicable (NA)</b> or{" "}
              <b>Missing Data (MD)</b>
            </li>
            The other available fields depend on the type of variable created.
          </p>
        </SubSection>
        <SubSection title="Definiing Alerts">
          <p>
            It is possible to define conditions that display an alert if a
            certain value is too high for our variable; this alert will be
            present in the eCRF.
          </p>
          <p>
            In the variable editing menu, go to <b>Advanced Settings</b>.
          </p>
          <img
            src={paramsavances}
            alt="Advanced Parameters"
            className="centered"
          />
          <p>
            You can choose between two different types of alerts:
            <li>
              <b>Warnings</b> (1): low-level alerts that can be ignored by
              clicking the icon
            </li>
            <li>
              <b>Critical Errors</b> (2): high-level alerts that must be
              addressed to allow locking of the forms
            </li>
          </p>
          <img src={alertes} alt="Types of Alerts" className="centered" />
          <Note type="note">
            Form submissions are never blocked, even in the presence of alerts.
          </Note>
        </SubSection>
      </Section>
      <Section
        id="editing-an-existing-variable"
        title="Editing an Existing Variable"
      >
        <p>
          To access the editing of an already existing variable, there are two
          possibilities:
          <li>
            The variable is used in the template you are editing; you can click
            the <b>menu button</b> (1) and select <b>Edit</b> (2)
          </li>
          <li>
            You can directly click on the <b>variable</b> (3) in the list of
            elements on the left side of the screen.
          </li>
        </p>
        <img src={editervariable} alt="Edit a Variable" className="centered" />
      </Section>
    </PageLayout>
  );
}
